<template>
  <swiper class="swiper" :options="swiperOptions">
    <swiper-slide v-for="(item, i) in postersData" :key="i">
      <template v-if="item.posterType==3">
        <a :href=item.linkPath target="_blank">
          <img :src="item.picturePath" alt="" />
        </a>
      </template>
      <template v-else>
        <div v-if="item.details!='' || item.linkPath!='1'" @click="$router.push({ name: 'bannerContent', params: { cId: i } })">
          <img :src="item.picturePath" alt="" />
        </div>
        <div v-else>
        <img :src="item.picturePath" alt="" />
        </div>
      </template>
     
    </swiper-slide>
    <div class="swiper-pagination swiper-pagination-orange" slot="pagination"></div>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapActions, mapState } from "vuex";
export default {
  name: "CarouselBanner",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {},
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "my-bullet-active",
        },
      },
    };
  },
  computed: {
    ...mapState("posters", ["postersData"]),
  },
  created() {
    this.getPosters();
  },
  mounted() {},
  methods: {
    ...mapActions("posters", ["getPosters"]),
  },
};
</script>
<style lang="scss" scoped>
img {
  // width: 100%;
  width: 100%;
  // max-width: 414px
}
</style>
<style lang="scss">
.my-bullet-active {
  background-color: #ff6600;
  opacity: 1;
}
</style>
