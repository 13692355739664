<template>
  <div class="Home">
    <div class="zone__carousel">
      <Carousel />
    </div>
    <Marquee />
    <!-- 選擇區塊 -->
    <div class="zone__choice">
      <div class="box__choice">
        <div :class="choice == 'recharge' ? 'active' : 'no-active'" @click="getData('recharge')">
          充值
        </div>
        <div :class="choice == 'withdraw' ? 'active' : 'no-active'" @click="getData('withdraw')">
          提现
        </div>
      </div>
      <div class="box__select" @click="selectShow = !selectShow">
        <div class="btn__select">
          <div>{{ selectValue.name }}</div>
          <div>
            <img :src="require('../../assets/images/public/ic_home_drop_down.webp')" alt="" />
          </div>
        </div>
        <div class="hidden__select" v-if="selectShow == true">
          <div v-for="item in selectList" :key="item.id">
            <div @click="changeSelectValue(item)">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="box__tips">
        <img src="@/assets/images/public/Group 2210@3x.png" alt="" />
        <div>往下滑动有更多商家供您选择 !</div>
        <img src="@/assets/images/public/Group 2210@3x.png" alt="" />
      </div>
    </div>
    <!-- 輸入金額區塊 -->
    <div class="zone__input">
      <div class="wrap__input">
        <div class="box__symbol">¥</div>
        <div class="box__input">
          <input autocomplete="off" type="number" :placeholder="`请输入${inputName}金额`" v-model="moneyValue" @keyup="replaceFloatingPoint()" @afterpaste="replaceFloatingPoint()" />
        </div>
        <div v-if="choice == 'withdraw'" class="box__input">
          <input type="password" maxlength="6" :placeholder="`请输入安全密码`" v-model="securityCode" />
        </div>
        <div class="box__img" v-if="moneyValue != ''" @click="moneyValue = null">
          <img src="../../assets/images/public/ic_home_shutdown.webp" alt="" />
        </div>
      </div>
      <div class="wrap__text" @click="fastOrderSubmit()">一键{{ inputName }}</div>
    </div>
    <!-- 內容區塊 -->
    <div class="zone__content" ref="zone__content">
      <div v-if="getNowChoice != ''">
        <div class="container__info" v-for="(item, i) in getNowChoice" :key="i" @click="openPopup(item)">
          <div class="flex-sb-c mb_4px">
            <div class="wrap__info">
              <div class="box__img tw-w-7 tw-h-7 tw-rounded-full tw-overflow-hidden">
                <img class="tw-w-full tw-h-full" :src="getAvatar(item.traderAvatar)" alt="" />
              </div>
              <div class="box__name">
                {{ item.traderName }}
              </div>
              <div class="box__signature">
                {{ item.signature ? item.signature : "无签名" }}
              </div>
            </div>
            <div class="wrap__info">
              <div class="text">成交笔数 {{ item.orderSuccess }}</div>
              <div class="text">
                &nbsp;|&nbsp;
              </div>
              <div class="text">成交率 {{ item.successRate }}</div>
            </div>
          </div>
          <div class="flex-sb-c mb_4px">
            <div class="wrap__info">
              <div class="text2" v-if="choice == 'recharge'">充值限額 : ¥ {{ item.minLimit }} ~ {{ item.commission }}</div>
              <div class="text2" v-else-if="choice == 'withdraw'">提现限額 : ¥ {{ item.withdrawMinLimit }} ~ {{ item.maxLimit }}</div>
            </div>
            <div class="wrap__info">
              <div class="box__img2">
                <img v-if="item.bankcardStatus == 1" src="../../assets/images/public/ic_my_money_aisle_bank.webp" alt="" />
                <img v-if="item.alipayStatus == 1" src="../../assets/images/public/ic_my_money_aisle_alipay.webp" alt="" />
                <img v-if="item.wechatpayStatus == 1" src="../../assets/images/public/ic_my_money_aisle_wechat.webp" alt="" />
              </div>
              <div class="row__btnn">
                {{ inputName }}
              </div>
            </div>
          </div>
          <div class="flex-sb-c">
            <div class="wrap__info">
              <div class="text signature">
                {{ item.signature ? item.signature : "无签名" }}
              </div>
            </div>
            <div class="wrap__info">
              <div class="btnn">
                {{ inputName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <NoData />
      </div>
    </div>
    <!-- 彈窗區塊 -->
    <div v-if="showPopup == true">
      <PopupUserTrade :isTraderRelations="isTraderRelations" :userData="userData" :inputName="inputName" :popupValue="popupValue" :choice="choice" :usersData="usersData" @isPopupUserTrade="isPopupUserTrade" @postTraderRelations="postTraderRelations" />
    </div>
    <!-- 快速提現承擔費用 -->
    <div class="PopupCheckPayMoney" v-if="checkFastPopup == true">
      <div class="container__main">
        <div class="title">重要提示1</div>
        <div class="content">
          您的提现金额 {{ moneyValue }}，其中
          {{ moneyValue - withdrawCheckoutData["withdrawCommission"] }}
          为常驻用户充值，未经过商户流通。因本平台已向常驻用户支付商户奖励，为防止刷单套取平台奖励，您需要承担此笔费用共计
          {{ withdrawCheckoutData["feeAmount"] }} 元。
        </div>
        <div class="box__btn">
          <div @click="checkFastPopup = false">取消</div>
          <div @click="fastSureTakeMyMoney()">確定</div>
        </div>
      </div>
    </div>

    <div class="PopupCheckPayMoney" v-if="showMerchantOrderAlert">
      <div class="container__main">
        <div class="title">重要提示</div>
        <div class="content_center">
          【商户充值订单】并未生成，请先确认已【绑定手机号】并且充值余额后重新提单
        </div>
        <div class="box__btn">
          <div @click="showMerchantOrderAlert = false">確定</div>
        </div>
      </div>
    </div>
    <FooterMenu />
  </div>
</template>

<script>
import Carousel from "@/components/CarouselBanner.vue";
import Marquee from "@/components/MarqueeText.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import PopupUserTrade from "@/components/PopupUserTrade.vue";
import NoData from "@/components/NoData.vue";
import { mapActions, mapState } from "vuex";
import { Message } from "element-ui";
import cookie from "js-cookie";
import tim from "@/utils/tim";

export default {
  name: "Home",
  components: {
    Carousel,
    Marquee,
    FooterMenu,
    NoData,
    PopupUserTrade,
  },
  data() {
    return {
      data: [],
      dataa: [],
      choice: "recharge",
      selectShow: false,
      selectValue: { name: "全部", value: "" },
      selectList: [
        { name: "全部", value: "" },
        { name: "支付宝", value: "alipay" },
        { name: "微信支付", value: "wechatpay" },
        { name: "银行卡", value: "bankcard" },
      ],
      moneyValue: "",
      inputName: "充值",
      userData: [],
      showPopup: false,
      isTraderRelations: false,
      popupValue: 0,
      checkPopup: false,
      checkFastPopup: false,
      screenWidth: null,
      scrollActive: false,
      zoneContentTop: 0,
      showMerchantOrderAlert: false,
      securityCode: "",
      isGobyfast: false,
    };
  },
  computed: {
    ...mapState("buy", ["buyData", "buyFastTradersData"]),
    ...mapState("sell", ["sellTradersData"]),
    ...mapState("trader", ["traderRelationsData"]),
    ...mapState("users", ["usersData"]),
    ...mapState("withdraw", ["withdrawFastTradersData"]),
    ...mapState("orders", ["withdrawSubmitData", "withdrawCheckoutData"]),

    getNowChoice() {
      if (this.choice == "recharge") {
        return this.buyData;
      } else {
        return this.sellTradersData;
      }
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.token) {
          cookie.set("token", this.$route.query.token);
          if (this.$route.query.orderNo) {
            // cookie.set("orderNo", this.$route.query.orderNo)
            this.$router.push({ name: "my", query: { orderNo: this.$route.query.orderNo } });
          }
        }
        if (this.$route.query.isValid && this.$route.query.isValid === "false") {
          this.showMerchantOrderAlert = true;
        }
      },
      immediate: true,
    },
    checkFastPopup(e) {
      if (e == true) {
        if (this.withdrawCheckoutData.feeAmount === 0) {
          this.fastSureTakeMyMoney();
        }
      }
    },
    selectValue() {
      this.callApi();
    },
    buyData() {
      this.data = this.buyData;
    },
    sellData() {
      this.dataa = this.sellData;
    },
    traderRelationsData() {
      this.findTraderRelations();
    },
    showPopup(e) {
      const self = this;
      if (e == false) {
        self.isTraderRelations = false;
      }
      if (e == false) {
        this.userData = [];
      }
    },
    buyFastTradersData() {
      this.$router.push({
        path: "/orderInformation",
        query: {
          orderId: this.buyFastTradersData.orderId,
          orderNo: this.buyFastTradersData.orderNo,
        },
      });
    },
    withdrawCheckoutData() {
      if (this.isGobyfast) {
        if (this.withdrawCheckoutData.feeAmount === 0) {
          this.fastSureTakeMyMoney();
        } else {
          this.checkFastPopup = true;
        }
      }
    },
    withdrawSubmitData() {
      this.$router.push({
        path: "/waitingForBuyersPayment",
        query: {
          orderNo: this.withdrawSubmitData.orderNo,
          orderId: this.withdrawSubmitData.orderId,
        },
      });
    },
    withdrawFastTradersData(e) {
      console.log(e);
      this.$router.push({
        path: "/waitingForBuyersPayment",
        query: {
          traderId: e.traderAccounts[0]["traderId"],
          amount: e.amount,
          orderNo: e.orderNo,
          orderId: e.orderId,
        },
      });
    },
  },
  created() {
    if (this.$route.query.token) {
      //cookie.remove("token");
      cookie.set("token", this.$route.query.token);
      this.getSignalData({ signalNo: this.$route.query.token }).then((res) => {
        tim.init({ userID: res.data.id, userSig: res.data.genSig });
      });
    } else {
      if (cookie.get("token")) {
        this.getSignalData({ signalNo: this.token }).then((res) => {
          tim.init({ userID: res.data.id, userSig: res.data.genSig });
        });
      }
    }

    this.init();
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    if (this.$store.state.orders.orderNo != "") {
      var url = `/my?orderNo=${this.$store.state.orders.orderNo}`;
      this.$router.push(url);
    }
  },
  methods: {
    ...mapActions("buy", ["getBuyTraders", "getBuyFastTrader"]),
    ...mapActions("sell", ["getSellTraders"]),
    ...mapActions("withdraw", ["getWithdrawFastTraders"]),
    ...mapActions("trader", ["getTraderRelations", "postTraderRelations"]),
    ...mapActions("orders", ["postWithdrawSubmit", "postWithdrawCheckout"]),
    ...mapActions("users", ["getUsers"]),
    ...mapActions("message", ["getSignalData"]),
    getAvatar(avatar) {
      if (avatar) return avatar;
      return require("@/assets/images/public/ic_my_head.webp");
    },
    replaceFloatingPoint() {
      this.moneyValue = this.moneyValue.toString().replace(/\D/g, "");
      this.popupValue = this.popupValue.toString().replace(/\D/g, "");
    },
    getData(choiceName) {
      this.choice = choiceName;
      this.inputName = choiceName == "recharge" ? "充值" : "提现";
      this.selectValue = { name: "全部", value: "" };
      this.callApi();
    },
    callApi() {
      const self = this;
      self.moneyValue = "";
      self.data = [];
      if (self.choice == "recharge") {
        self.getBuyTraders({
          channelCode: self.selectValue.value,
        });
        self.data = this.buyData;
      } else {
        self.getSellTraders({
          channelCode: self.selectValue.value,
        });
        self.data = this.sellData;
      }
    },
    init() {
      this.getBuyTraders({
        channelCode: "",
      });
      this.getTraderRelations({});
      this.getUsers({});
      this.data = this.buyData;
    },
    changeSelectValue(item) {
      this.selectValue = item;
      this.callApi();
    },
    openPopup(item) {
      this.userData = null;
      this.userData = item;
      this.showPopup = true;
      this.findTraderRelations();
    },
    findTraderRelations() {
      const self = this;
      self.traderRelationsData.forEach((e) => {
        if (e.traderId == self.userData.traderId) {
          self.isTraderRelations = true;
        }
      });
    },
    fastOrderSubmit() {
      this.isGobyfast = true;
      const self = this;

      if (self.choice == "recharge") {
        if (self.moneyValue == "") {
          Message({
            message: "请输入充值金额",
            iconClass: "x",
            center: true,
            customClass: "error_message",
          });
          return;
        }
        let data = {
          amount: self.moneyValue,
        };
        this.getBuyFastTrader(data);
      } else if (self.choice == "withdraw") {
        if (self.moneyValue == "") {
          Message({
            message: "请输入提现金额!",
            iconClass: "x",
            center: true,
            customClass: "error_message",
          });
          return;
        }
        let data = {
          amount: self.moneyValue,
          securityCode: this.securityCode,
        };
        this.postWithdrawCheckout(data);
      }
    },
    fastSureTakeMyMoney() {
      console.log("fastSureTakeMyMoney");
      let data = {
        amount: this.moneyValue,
        securityCode: this.securityCode,
      };
      this.getWithdrawFastTraders(data);
    },
    isPopupUserTrade(isPopupUserTrade) {
      this.showPopup = isPopupUserTrade;
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  // outline: 1px red solid;
}

@media screen and (max-width: 414px) {
  .Home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.Home {
  // width: 100%;
  // max-width: 414px;
  // margin: 0 auto;

  height: 100%;
  // max-width: 414px;
  display: flex;
  flex-direction: column;

  @media (min-width: 480px) {
    max-width: unset;
    padding: 1rem;
  }

  .zone__carousel {
    flex: auto 0 0;

    @media (min-width: 480px) {
      display: none;
    }
  }

  .zone__choice {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .box__tips {
      display: none;

      @media (min-width: 480px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        background-color: #2e86ff;
        width: 240px;
        height: 26px;
      }

      img {
        width: 8px;
      }

      div {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .box__choice {
      width: 50%;
      display: flex;

      div {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: #797979;
        font-weight: bold;
        font-size: 1.2rem;

        &.active {
          position: relative;
          color: var(--orange);

          &::before {
            content: "";
            width: 50%;
            height: 3px;
            background-color: var(--orange);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }

    .box__select {
      position: relative;

      .btn__select {
        display: flex;
        align-items: center;
        color: #999999;
        margin-right: 1rem;

        div {
          margin-right: 0.1rem;
        }

        img {
          width: 0.8rem;
        }
      }

      .hidden__select {
        background-color: aliceblue;
        position: absolute;
        right: 0rem;
        top: 1.8rem;
        width: 6rem;
        z-index: 99;

        div {
          padding-top: 0.3rem;
          padding-bottom: 0.3rem;
        }
      }
    }
  }

  .zone__input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--light-gray);

    .wrap__input {
      display: flex;
      align-items: center;
      width: 75%;

      input {
        outline-style: none;
        border: 0px;
        width: 100%;
      }

      .box__input {
        width: 100%;

        input {
          width: 100%;
          font-weight: bold;
          font-size: 1rem;
        }
      }

      .box__img {
        width: 1.3rem;
        font-size: 0;

        img {
          width: 100%;
        }
      }

      .box__symbol {
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .wrap__text {
      color: var(--orange);
    }
  }

  .zone__content {
    // width: 100%;
    // height: 121vw;
    // overflow-y: scroll;
    // width: 100%;
    // height: 44vh;
    // height: 44vw;
    // overflow-y: auto;
    // max-height: 300px;

    display: flex;
    flex-direction: column;
    flex: auto;
    width: 100%;
    overflow-y: auto;
    margin-bottom: 74px;

    @media (min-width: 480px) {
      margin-bottom: 0;
    }

    .container__info {
      width: 100%;
      padding: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      position: relative;

      .wrap__info {
        display: flex;
        align-items: center;
        width: 50%;

        &:nth-child(2) {
          justify-content: flex-end;
        }

        .box__img {
          width: 1.8rem;
          margin-right: 0.6rem;
          font-size: 0;

          img {
            width: 100%;
            border-radius: 50%;
          }
        }

        .box__img2 {
          width: 1.2rem;
          height: 1.2rem;
          font-size: 0;
          display: flex;
          justify-content: flex-end;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .box__name {
          font-size: 1rem;
          font-weight: bold;
        }

        .box__signature {
          display: none;

          @media (min-width: 480px) {
            margin-bottom: 0;
            margin-left: 10px;
            color: var(--gray);
            font-size: 0.9rem;
            text-align: left;
            display: block;
          }
        }

        .signature {
          @media (min-width: 480px) {
            display: none;
          }
        }

        .text {
          color: var(--gray);
          font-size: 0.9rem;
          text-align: left;
        }

        .text2 {
          font-size: 0.9rem;
          text-align: left;
        }

        .row__btnn {
          display: none;

          @media (min-width: 480px) {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            background-color: var(--orange);
            color: white;
            font-size: 0.9rem;
            display: block;
            margin-left: 0.5rem;
          }
        }

        .btnn {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
          background-color: var(--orange);
          color: white;
          font-size: 0.9rem;

          @media (min-width: 480px) {
            display: none;
          }
        }
      }
    }
  }

  .zone__popup {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    .container__close {
      padding-top: 6rem;
    }

    .container__info {
      width: 100%;
      height: 100%;
      background-color: var(--white);
      border-radius: 10px 10px 0 0;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      overflow-y: scroll;
      position: relative;

      .wrap__close {
        position: absolute;
        right: 0.6rem;
        top: 0.4rem;
        width: 1.5rem;

        img {
          width: 100%;
        }
      }

      .wrap__info {
        display: flex;
        align-items: center;

        .box__img {
          width: 3rem;
          margin-right: 0.6rem;
          font-size: 0;

          img {
            width: 100%;
          }
        }

        .box__img2 {
          width: 1.2rem;
          height: 1.2rem;
          margin-right: 0.6rem;
          font-size: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .box__name {
          font-size: 1rem;
          font-weight: bold;
        }

        .text {
          color: var(--gray);
          font-size: 0.9rem;
          text-align: left;
        }

        .text2 {
          font-size: 0.9rem;
        }

        .btnn {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
          background-color: var(--orange);
          color: white;
          font-size: 0.9rem;
        }

        .collect {
          margin-left: 0.4rem;
          font-size: 0.7rem;
          color: var(--orange);
          border-bottom: 1px solid rgb(255, 115, 0);
        }

        .w100 {
          margin-bottom: 0.4rem;
        }
      }

      .wrap__input {
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;

        .box__img {
          width: 1.6rem;
          margin-right: 0.6rem;
          font-size: 0;
          display: flex;
          justify-content: flex-end;

          img {
            width: 100%;
          }
        }

        .box__input {
          width: 100%;
          margin-top: 0.5rem;

          input {
            width: 100%;
            outline-style: none;
            border-bottom: 0px;
            border-right: 0px;
            border-top: 1px solid lightgray;
            border-left: 1px solid lightgray;
            padding: 0.5rem;
            font-size: 1rem;
          }
        }

        .box__tips {
          margin-top: 0.5rem;
          text-align: left;
          font-size: 0.8rem;
          color: var(--gray);
        }

        .box__text {
          font-weight: bold;
        }
      }

      .wrap__btn {
        width: 100%;
        font-weight: bold;

        .btnn:nth-child(1) {
          padding: 0.7rem 4.2rem;
          border-radius: 20px;
          background-color: #d6d6d6;
        }

        .btnn:nth-child(2) {
          padding: 0.7rem 4.2rem;
          border-radius: 20px;
          background-color: var(--orange);
          color: aliceblue;
        }
      }

      .wrap__remark {
        width: 100%;
        margin-top: 2rem;
        text-align: left;
        color: var(--gray);
        word-wrap: break-word;
      }
    }
  }
}

.PopupCheckPayMoney {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .container__main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: aliceblue;
    width: 80%;
    height: fit-content;
    padding: 2rem 1rem;
    border-radius: 15px;

    .title {
      font-weight: bold;
      color: var(--orange);
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }

    .content {
      text-align: left;
      margin-bottom: 1.5rem;
    }

    .content_center {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .box__btn {
      display: flex;
      justify-content: space-around;

      div {
        width: 40%;
        padding: 0.8rem 0rem;
        color: aliceblue;
        border-radius: 20px;

        &:nth-child(1) {
          background-color: var(--light-black);
        }

        &:nth-child(2) {
          background-color: var(--orange);
        }
      }
    }
  }
}

// @media (max-width: 1200px) {
//   .zone__content {
//     height: 66vh;
//   }
// }

// @media (max-width: 575.98px) {
//   .zone__content {
//     height: 52vh;
//   }
// }

// @media screen and (min-width:481px) and (max-width:768px) {
//   .zone__content {
//     height: 63vh;
//   }
// }
</style>
