<template>
  <div class="MarqueeText" style="width:100%;height:2rem;">
    <div class="wrap__title">
      <div>公告</div>
      <div class="box__img">
        <img :src="require('../assets/images/public/ic_home_announcement.png')" alt="" />
      </div>
    </div>
    <DynamicMarquee class="DynamicMarquee" direction="row" :reverse="true" :repeatMargin="100"
      :speed="{ type: 'pps', number: 50 }">
      <span
        style="width: auto; display: inline;  margin-right: 1.5rem; text-align:left;white-space:nowrap;overflow:hidden;white-space: nowrap;"
        v-for="(item, i) in noticeData" :key="i">
        {{ '【'+item.title + ' : ' + item.content + '】' }}
      </span>
    </DynamicMarquee>
    <div class="wrap__btn" @click="$router.push({ path: '/announcement' })">
      <div>
        <img :src="require('../assets/images/public/ic_bulletin.png')" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import DynamicMarquee from "vue-dynamic-marquee";
import { mapActions, mapState } from "vuex";
export default {
  name: "MarqueeText",
  components: {
    DynamicMarquee,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("notice", ["noticeData"]),
  },
  created() {
    this.getNotice();
  },
  methods: {
    ...mapActions("notice", ["getNotice"]),
  },
};
</script>
<style lang="scss" scoped>
.MarqueeText {
  display: flex;
  align-content: center;

  .wrap__title {
    width: 7rem;
    display: flex;
    align-content: center;
    justify-content: space-evenly;

    div {
      display: flex;
      align-self: center;
    }

    .box__img {
      width: 1.2rem;
      height: 1.2rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .wrap__btn {
    display: flex;
    align-self: center;
    margin-right: 1rem;

    div {
      width: 1rem;
      font-size: 0;

      img {
        width: 100%;
      }
    }
  }
}

.DynamicMarquee {
  transform: translateY(8px);
  @media (min-width:480px){
    transform: translateY(2.5px);
  }
}
</style>
